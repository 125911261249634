<template>
    <div>
      <div class="w-full h-full px-3 py-5">
        <loader v-if="isLoading" size="xxs" :loader-image="false" />
        <template v-else>
          <div class="flex flex-col gap-8">
            <div class="flex justify-start items-center gap-8">
              <h1 class="text-xl text-left font-extrabold">Time Tracking</h1>
              <breadcrumb :items="breadcrumbs" />
            </div>
            <div class="flex flex-col gap-0 my-5">
              <div class="shadow rounded">
                <tab
                  :border="true"
                  :tabs="tabs"
                  :active-tab="currentTab"
                  @currentTab="handleCurrentTab($event)"
                />
              </div>
              <Locations v-if="currentTab === 'Location(s)'" />
              <Calendar v-if="currentTab === 'Calendar'" />
              <ClockInOut v-if="currentTab === 'Clock In/Out'" />
              <Absence v-if="currentTab === 'Absence'" />
            </div>
          </div>
        </template>
      </div>
    </div>
  </template>

  <script>
  import Loader from "@scelloo/cloudenly-ui/src/components/loader"
  import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb"
  import Tab from "@scelloo/cloudenly-ui/src/components/tab"

  export default {
    components: {
      Loader,
      Breadcrumb,
      Tab,
      Locations: () => import("./Locations"),
      ClockInOut: () => import ("./ClockInOut"),
      Absence: () => import ("./Absence"),
      Calendar: () => import ("./Calendar"),
    },
    computed: {
      breadcrumbs(){
        return [
          { disabled: false, text: "Time Tracking", href: "Time Tracking", id: "Time Tracking" },
          { disabled: false, text: this.currentTab, href: this.currentTab, id: this.currentTab },
        ]
      }
    },
    data() {
      return {
        isLoading: false,
        showError: false,
        currentTab: this.$route.query.currentTab || "Location(s)",
        tabs: ['Location(s)', 'Calendar', 'Clock In/Out', 'Absence'],
      };
    },
    methods: {
      handleCurrentTab(currentTab) {
        if (this.currentTab !== currentTab) {
          this.currentTab = currentTab
          this.$router.push({ query: { currentTab } })
        }
      }
    },
   /* async mounted() {
      try {
        await this.$handlePrivilege("timeOff", "viewRegister")
        this.showError = false
      } catch (error) {
        this.showError = true
      }
    } */
  }
  </script>
